<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card color="primary" elevation="24" class="pa-12 rounded-lg" dark>
          <v-card-title class="justify-center">{{
            `PAYMAPI ${$t('app')}`
          }}</v-card-title>

          <v-card-subtitle class="d-flex justify-center">
            {{ $t('pages.login.subheader') }}
          </v-card-subtitle>
          <v-card-text class="d-flex justify-center">
            <v-tooltip v-for="(item, i) in $attrs.locales" :key="i" bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  x-large
                  @click="$emit('update-locale', null, item)"
                >
                  <v-icon>
                    {{ `flag-icon flag-icon-${item.icon}` }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ item.value === 'tr' ? 'Türkçe' : 'English' }}
              </span>
            </v-tooltip>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-text>
            <v-alert
              v-model="loginError"
              class="mt-6"
              text
              dismissible
              @input="loginError = !loginError"
              transition
            >
              {{ $t('pages.login.login_error') }}
            </v-alert>

            <v-form ref="form">
              <v-text-field
                v-model="form.clientCode"
                :label="$t('pages.login.clientCode')"
                :rules="required"
                prepend-inner-icon="mdi-office-building"
              ></v-text-field>
              <v-text-field
                v-model="form.user"
                :label="$t('pages.login.username')"
                :rules="required"
                prepend-inner-icon="mdi-account"
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="form.pass"
                :label="$t('pages.login.password')"
                :rules="required"
                prepend-inner-icon="mdi-lock"
                type="password"
                @keypress.enter="tryToLogin"
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="tryToLogin" block>
              {{ $t('pages.login.sign_in') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import validate from '@/mixins/validate'
import requests from '@/mixins/requests'
export default {
  metaInfo: function() {
    return {
      title: `${this.$t('pages.titles.login')}`,
      titleTemplate: `%s | PAYMAPI ${this.$t('app')}`
    }
  },
  mixins: [validate, requests],
  data: () => ({
    form: {
      user: null,
      pass: null,
      clientCode: null
    }
  }),

  created() {
    localStorage.removeItem('currentUser')
  },

  methods: {
    async tryToLogin() {
      if (this.$refs.form.validate()) {
        this.form.clientCode = parseInt(this.form.clientCode)
        const data = await this._tryToLogin(this.form)
        const _currentUser = {
          username: this.form.user,
          token: data.token,
          locale: 'en'
        }
        localStorage.setItem('currentUser', JSON.stringify(_currentUser))
        this.$router.push({ name: 'Home' })
      }
    }
    /* setLocale(item) {
      console.log(item);
      localStorage.setItem("locale", JSON.stringify(item))
      this.$i18n.value = item.value
    } */
  }
}
</script>
