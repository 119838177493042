export default {
  /* data: () => ({
    required: [],
  }), */
  computed: {
    required() {
      return [v => !!v || this.$t('errors.required')]
    }
  }
}
